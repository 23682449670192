const socialLinks = [
  {
    key: 'github',
    url: 'https://github.com/AlisonV2',
    icon: 'bi bi-github',
    name: 'Github',
  },
  {
    key: 'linkedin',
    url: 'https://www.linkedin.com/in/alison-vandromme/',
    icon: 'bi bi-linkedin',
    name: 'Linkedin',
  },
  {
    key: 'malt',
    url: 'https://www.malt.fr/profile/alisonvandromme1',
    icon: '',
    name: 'Malt',
  },
  {
    key: 'medium',
    url: 'https://medium.com/@alisonv2',
    icon: 'bi bi-medium',
    name: 'Medium',
  },
];

export default socialLinks;
