<template>
  <div class="row contact">
    <div class="col-12 section-title active slide-down-d0">
      <h2>Contact me</h2>
    </div>
    <div class="col-12 col-lg-10 fade-in-medium">
      <p>
        Below are the links to my Github account, where you will find all my
        personal and school projects, at least the public ones. You will also
        find my Medium profile, Linkedin account, and Malt profile.
      </p>
      <p class="mb-0">
        My inbox is always open whether you have a question or want to say hi. <br />
        I will get back to you as soon as possible!
      </p>
      <contact-form text="Send me an email" />
    </div>
  </div>
  <social-links />
</template>

<script>
import ContactForm from '@/components/contact/Form'
import SocialLinks from '@/components/contact/SocialLinks';
export default {
  name: 'Contact',
  components: {
    SocialLinks,
    ContactForm,
  },
};
</script>
