<template>
  <div class="row mt-4 fade-in-medium">
    <div class="col-12 text-center">
      <h3 class="social-text">{{ currentLink }}</h3>
    </div>
    <div class="social-links" @mouseleave="currentLink = 'Get in touch!'">
      <div
        class="link-item"
        v-for="link in socialLinks"
        :key="link.key"
        @mouseover="currentLink = link.name"
        @click="openLink(link.url)"
      >
        <a :href="link.url" target="_blank" rel="noopener noreferrer"
          ><i :class="link.icon" v-if="link.key !== 'malt'"></i
          ><i v-else class="malt"><malt-icon width="40pt" height="40pt" /></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import socialLinks from '@/utils/socialLinks';
import MaltIcon from '@/components/app/MaltIcon';
export default {
  name: 'SocialLinks',
  components: {
    MaltIcon,
  },
  data() {
    return {
      currentLink: 'Get in touch!',
      socialLinks,
    };
  },
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    }
  }
};
</script>
